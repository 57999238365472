
import { Component, Vue } from 'vue-property-decorator';
import { NeWidgetCookiesPolicy } from '@ne/ne-vue2-lib';
import { mneApiCookiesPath } from '@/api/config';

export interface MneCookiesResponse {
  data: {
    data: {
      attributes: {
        updateDate: string;
        value: string;
      }
      id: string;
      type: string;
    }
  }
}

@Component({
  name: 'Cookies',
  components: {
    NeWidgetCookiesPolicy
  }
})
export default class Cookies extends Vue {
  policyText = '';

  async created (): Promise<void> {
    const policyResponse = (await this.$apiMne.get(mneApiCookiesPath)) as MneCookiesResponse;
    this.policyText = policyResponse.data.data.attributes.value;
  }
}
